import React from 'react'
import './styles/Footer.css'
import logo from 'app/assets/images/web-logo.png'
import SiteGrid from "../ui/SiteGrid"
import IconContainer from "../ui/IconContainer"
import { Link } from "react-router-dom"

export default function Footer() {
  return (
    <footer className="footer">
      <SiteGrid className="footer-grid">
        <div className="column">
          <div className="logo">
            <img
              src={logo}
              alt="logo"
            />
            <h4>789 Themes</h4>
          </div>
          <small>Your ultimate website themes collection.</small>
          <div className="socials">
            <IconContainer
              icon="fab fa-facebook-f"
              iconColor="var(--secondary)"
              borderColor="#eee"
              round
              title="Facebook"
              onClick={() => window.open("https://www.facebook.com/789themes/", "_blank")}
            />
            <IconContainer
              icon="fab fa-twitter"
              iconColor="var(--secondary)"
              borderColor="#eee"
              round
              title="Twitter"
              onClick={() => window.open("https://twitter.com/789themes", "_blank")}
            />
            <IconContainer
              icon="fab fa-instagram"
              iconColor="var(--secondary)"
              borderColor="#eee"
              round
              title="Instagram"
              onClick={() => window.open("https://www.instagram.com/789themes/", "_blank")}
            />
            <IconContainer
              icon="fas fa-leaf"
              iconColor="var(--secondary)"
              borderColor="#eee"
              round
              title="Envato Market"
              onClick={() => window.open("https://themeforest.net/user/urielbitton", "_blank")}
            />
            <IconContainer
              icon="fab fa-dribbble"
              iconColor="var(--secondary)"
              borderColor="#eee"
              round
              title="Dribbble"
              onClick={() => window.open("https://dribbble.com/789themes", "_blank")}
            />
            <IconContainer
              icon="fab fa-pinterest"
              iconColor="var(--secondary)"
              borderColor="#eee"
              round
              title="Pinterest"
              onClick={() => window.open("https://www.pinterest.ca/789themes/", "_blank")}
            />
          </div>
        </div>
        <div className="column side-column">
          <h5>Get Started</h5>
          <Link to="/themes">Themes</Link>
          <Link to="/services">Services</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/about">About Us</Link>
        </div>
        <div className="column side-column">
          <h5>Resources</h5>
          <Link to="/themes">Themes</Link>
          <Link to="/customizer">Try Customizer</Link>
          <Link to="/custom-designs">Custom Designs</Link>
          <Link to="/blog">Blog</Link>
        </div>
        <div className="column side-column">
          <h5>Helpful Links</h5>
          <Link to="/themes">Privacy Policy</Link>
          <Link to="/customizer">Disclaimer</Link>
          <Link to="/blog">Site Map</Link>
          <Link to="/login">Sign In</Link>
        </div>
        <p className="credits">
          &copy; {new Date().getFullYear()} All Rights Reserved | 789 Themes
        </p>
      </SiteGrid>
    </footer>
  )
}
