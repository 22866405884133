import React from 'react'
import HeroSection from "app/components/homepage/HeroSection"
import Services from "app/components/homepage/Services"
import Subscribe from "app/components/homepage/Subscribe"
import Testimonials from "app/components/homepage/Testimonials"
import LiveChat from "app/components/layout/LiveChat"
import AboutUs from "app/components/homepage/AboutUs"
import Themes from "app/components/homepage/Themes"
import './styles/Homepage.css'
import Customizer from "app/components/homepage/Customizer"

export default function HomePage() {

  return (
    <div className="homepage">
      <HeroSection />
      <Themes />
      <Services />
      <AboutUs />
      <Testimonials />
      <Customizer />
      <Subscribe />
      <LiveChat />
    </div>
  )
}
