import React, { useState } from 'react'
import './styles/LiveChat.css'
import ChatBot from 'react-simple-chatbot'
import { chatbotSteps } from "app/data/chatbotData"
import IconContainer from "../ui/IconContainer"
import avatar from "app/assets/images/avatar.png"

export default function LiveChat() {

  const [showChat, setShowChat] = useState(false)

  return (
    <>
      <div className="floating-bubble">
        <IconContainer
          icon={`${!showChat ? 'fas fa-comment-dots' : 'fal fa-times'}`}
          iconColor="#fff"
          bgColor="var(--primaryGradient)"
          dimensions={60}
          iconSize={25}
          onClick={() => setShowChat(prev => !prev)}
        />
      </div>
      <div className={`chat-container ${showChat ? "show" : ""}`}>
        <ChatBot 
          steps={chatbotSteps} 
          botAvatar={avatar}
        />
      </div>
    </>
  )
}