import React, { useRef, useState } from 'react'
import SiteGrid from "../ui/SiteGrid"
import { themesArray, themesHeaders } from "app/data/general"
import SectionTitles from "../ui/SectionTitles"
import { Link } from "react-router-dom"
import AppButton from "../ui/AppButton"

export default function Themes() {

  const [activeCategory, setActiveCategory] = useState('all')

  const themesHeadersRender = themesHeaders.map((header, index) => {
    return <h6
      className={activeCategory === header.category ? 'active' : ''}
      onClick={() => setActiveCategory(header.category)}
      key={index}
    >
      {header.title}
    </h6>
  }) 

  const themesRender = themesArray
    .map((theme, index) => {
      return <ThemeItem
        theme={theme}
        show={theme.category === activeCategory || activeCategory === 'all'}
        key={index}
      />
    })

  return (
    <div className="themes-section home-section">
      <SiteGrid className="themes-flex">
        <SectionTitles
          title="Popular Themes"
          text={<>Explore our best-selling <span className="accent">themes</span></>}
          subtitle="Choose from our handpicked selection of our most popular themes"
          center
        />
        <div className="themes-header">
          {themesHeadersRender}
        </div>
        <div className="themes-grid">
          {themesRender}
        </div>
        <div className="btn-group">
          <AppButton
            label="View All Themes"
            link="/themes"
            rightIcon="far fa-arrow-right"
            className="color-shadow"
            url="/themes"
          />
        </div>
      </SiteGrid>
    </div>
  )
}

export const ThemeItem = ({ theme, show }) => {

  const itemRef = useRef(null)

  return <Link 
    className={`theme-item ${show ? 'show' : ''}`}
    to={theme.url}
  >
    <img
      src={theme.preImg}
      alt={theme.title}
      className="pre-img"
    />
    <div
      className="inner-item"
      style={{ height: itemRef?.current ? (itemRef.current?.naturalHeight / 10) - 490 : 0 }}
    >
      <img
        src={theme.fullImg}
        alt={theme.title}
        className="full-img"
        ref={itemRef}
      />
    </div>
    <div className="theme-info">
      <h5>{theme.title}</h5>
      <h6>{theme.type}</h6>
    </div>
  </Link>
}