export const menuLinks = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'Themes',
    url: '/themes',
  },
  {
    name: 'Services',
    url: '/services',
  },
  {
    name: 'About',
    url: '/about',
  },
  {
    name: 'Contact',
    url: '/contact',
  },
]