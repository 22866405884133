import React from 'react'
import SiteGrid from "../ui/SiteGrid"
import { servicesArray } from "app/data/general"
import SectionTitles from "../ui/SectionTitles"
import AppButton from "../ui/AppButton"

export default function Services() {

  const servicesRender = servicesArray.map((service, index) => {
    return <div
      className="service-item"
      key={index}
    >
      <img src={service.img} alt={service.title} />
      <h5>{service.title}</h5>
      <p>{service.text}</p>
      <AppButton
        label="Learn more"
        url={service.url}
        className="color-shadow"
      />
    </div>
  })

  return (
    <div
      className="services-section home-section"
    >
      <SiteGrid className="services-flex">
        <SectionTitles
          title="Services"
          text={<>Discover our suite of<br/>professional <span className="accent">services</span></>}
          subtitle="Our expert services are tailored to enhance your website and offer your clients the best online experience."
          center
        />
        <div className="services-grid">
          {servicesRender}
        </div>
      </SiteGrid>
    </div>
  )
}
