import adrasteaPre from 'app/assets/images/adrastea-pre.png'
import adrasteaFull from 'app/assets/images/adrastea-full.png'
import vortexPre from 'app/assets/images/vortex-pre.png'
import vortexFull from 'app/assets/images/vortex-full.png'
import adonisPre from 'app/assets/images/adonis-pre.png'
import adonisFull from 'app/assets/images/adonis-full.png'
import aphroditePre from 'app/assets/images/aphrodite-pre.png'
import aphroditeFull from 'app/assets/images/aphrodite-full.png'

import servicesThemes from 'app/assets/images/services-themes.jpg'
import servicesCustomizer from 'app/assets/images/services-customizer.jpg'
import servicesCustomDesigns from 'app/assets/images/services-custom-designs.jpg'

import testimonialImg1 from 'app/assets/images/testimonial-1.jpg'
import testimonialImg2 from 'app/assets/images/testimonial-2.jpg'
import testimonialImg3 from 'app/assets/images/testimonial-3.jpg'
import testimonialImg4 from 'app/assets/images/testimonial-4.jpg'
import testimonialImg5 from 'app/assets/images/testimonial-5.jpg'
import testimonialImg6 from 'app/assets/images/testimonial-6.jpg'
import testimonialImg7 from 'app/assets/images/testimonial-7.jpg'
import testimonialImg8 from 'app/assets/images/testimonial-8.jpg'


export const servicesArray = [
  { 
    title: 'Themes', 
    icon: 'fas fa-pencil-paintbrush', 
    text: 'Beautiful and functional themes that are optimized for SEO and mobile devices.',
    img: servicesThemes,
    url: '/services/themes'
  },
  {
    title: 'Customizer',
    icon: 'fas fa-tools',
    text: 'Online customizer that allows you to customize your theme without any coding knowledge.',
    img: servicesCustomizer,
    url: '/services/customizer'
  },
  {
    title: 'Custom Designs',
    icon: 'fas fa-browser',
    text: 'Custom website designs that are tailored to your brand and business needs.',
    img: servicesCustomDesigns,
    url: '/services/custom-designs'
  },
]

export const testimonialsArray = [
  {
    name: "Rachel Nguyen",
    position: "UX Designer",
    text: "I love the themes customizer. It's so easy to use and I can customize my website without any coding knowledge.",
    img: testimonialImg1
  },
  {
    name: 'Andrew Kim',
    position: 'Startup Founder',
    text: 'As a startup founder, I don\'t have a lot of time or resources to devote to website design. 789Themes has made it incredibly easy for me to create a professional-looking website that\'s helped me attract new customers and investors',
    img: testimonialImg2,
  },
  {
    name: "Ashley Williams",
    position: "Blogger",
    text: "I've tried many different themes over the years, but 789Themes is by far my favorite. Their themes are not only beautiful, but they're also incredibly functional and easy to use.",
    img: testimonialImg3,
  },
  {
    name: "Mikaela Smith",
    position: "eCommerce Store Owner",
    text: "The themes on 789Themes have helped me take my eCommerce store to the next level. With their stunning designs and powerful features, I've been able to create a website that stands out from the competition and drives more sales.",
    img: testimonialImg4
  },
  {
    name: "Laura Davis",
    position: "Web Developer",
    text: "789Themes has completely transformed my online presence. Their themes are easy to use, customizable, and best of all, affordable. I couldn't be happier with my experience!",
    img: testimonialImg5,
  },
  {
    name: "David Lee",
    position: "Web Developer",
    text: "Your website has made my job as a web developer so much easier. Your themes are easy to work with, customizable, and always super fast. I recommend them to all of my clients.",
    img: testimonialImg6
  },
  {
    name: "Brian Lee",
    position: "Digital Marketing Manager",
    text: "I've used these themes for several of my clients' websites, and the results have been amazing. The themes are not only beautiful, but they're also highly functional and optimized for SEO. Highly recommended!",
    img: testimonialImg7
  },
  {
    name: "Samantha Roberts",
    position: "Social Media Manager",
    text: "Your themes have helped me create beautiful and engaging landing pages for my social media campaigns. The templates are incredibly easy to use and always deliver great results.",
    img: testimonialImg8
  },
]

export const themesHeaders = [
  {
    title: 'All',
    category: 'all',
  },
  {
    title: 'Business',
    category: 'business',
  },
  {
    title: 'Creative',
    category: 'creative',
  },
  {
    title: 'E-commerce',
    category: 'e-commerce',
  },
  {
    title: 'Entertainement',
    category: 'entertainement',
  },
  {
    title: 'Retail',
    category: 'retail',
  },
  {
    title: 'Real Estate',
    category: 'real-estate',
  },
  {
    title: 'Technology',
    category: 'technology',
  },
]

export const themesArray = [
  {
    title: 'Aphrodite',
    category: 'healthcare',
    type: 'Healthcare',
    preImg: aphroditePre,
    fullImg: aphroditeFull,
    url: '/themes/aphrodite'
  },
  {
    title: 'Adonis',
    category: 'real-estate',
    type: 'Real Estate',
    preImg: adonisPre,
    fullImg: adonisFull,
    url: '/themes/adonis'
  },
  {
    title: 'Adrastea',
    category: 'creative',
    type: 'Creative Portfolio',
    preImg: adrasteaPre,
    fullImg: adrasteaFull,
    url: '/themes/adrastea'
  },
  {
    title: 'Vortex',
    category: 'technology',
    type: 'Digital Agency',
    preImg: vortexPre,
    fullImg: vortexFull,
    url: '/themes/vortex'
  },
]