import React, { useContext } from 'react'
import './styles/Navbar.css'
import { menuLinks } from "app/data/menuLinks"
import { StoreContext } from "app/store/store"
import SiteGrid from "../ui/SiteGrid"
import { Link, NavLink } from "react-router-dom"
import AppButton from "../ui/AppButton"
import logo from 'app/assets/images/web-logo.png'

export default function Navbar() {

  const { myUser, navScrolled, setShowMobileSidebar } = useContext(StoreContext)

  const menuLinksRender = menuLinks?.map((link, index) => {
    return <NavLink
      to={link.url}
      key={index}
    >
      {link.name}
      <hr />
    </NavLink>
  })

  return (
    <nav className={`navbar ${navScrolled ? 'scrolled' : ''}`}>
      <SiteGrid>
        <div className="left">
          <div
            className="mobile-btn"
            onClick={() => setShowMobileSidebar(true)}
          >
            <i className="far fa-bars" />
          </div>
          <Link
            to="/"
            className="logo"
          >
            <img src={logo} alt="logo" />
            <h4>Themes</h4>
          </Link>
        </div>
        <div className="right">
          <div className="menu">
            {menuLinksRender}
          </div>
          {
            myUser ?
              <AppButton
                label="Try Customizer"
                buttonType="primaryGradientBtn"
                url="/customizer"
                leftIcon="fas fa-paint-brush"
                className="color-shadow"
                /> :
                <AppButton
                label="Sign In"
                buttonType="primaryGradientBtn"
                url="/sign-in"
                leftIcon="fas fa-user"
                className="color-shadow"
              />
          }
        </div>
      </SiteGrid>
    </nav>
  )
}