import React, { } from 'react'
import ErrorPage from "app/pages/ErrorPage"
import HomePage from 'app/pages/HomePage'
import { Routes, Route } from "react-router"
import ThemePage from "app/pages/ThemePage"
import ThemesPage from "app/pages/ThemesPage"
import AboutPage from "app/pages/AboutPage"
import ServicesPage from "app/pages/ServicesPage"
import ContactPage from "app/pages/ContactPage"
import LoginPage from "app/pages/LoginPage"
import RegisterPage from "app/pages/RegisterPage"
import CustomizerPage from "app/pages/CustomizerPage"
import CustomDesignsPage from "app/pages/CustomDesignsPage"
import BlogPage from "app/pages/BlogPage"
import PrivacyPage from "app/pages/PrivacyPage"
import DisclaimerPage from "app/pages/DisclaimerPage"

export default function RoutesContainer() {

  return (
    <div className="routes-container">
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="/themes" element={<ThemesPage />} />
        <Route path="/customizer" element={<CustomizerPage />} />
        <Route path="/custom-designs" element={<CustomDesignsPage />} />
        <Route path="/themes/:themeId" element={<ThemePage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/sign-in" element={<LoginPage />} />
        <Route path="/sign-up" element={<RegisterPage />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
        <Route path="/disclaimer" element={<DisclaimerPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  )
}
