import React from 'react'
import './styles/SectionTitles.css'

export default function SectionTitles({title=null, text=null, subtitle=null, center=false}) {
  return (
    <div className={`section-titles ${center ? 'center' : ''}`}>
      { title && <h6>{title}</h6> }
      { text && <h2>{text}</h2> }
      { subtitle && <p>{subtitle}</p> }
    </div>
  )
}
