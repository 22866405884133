import React from 'react'
import AppButton from "../ui/AppButton"
import SiteGrid from "../ui/SiteGrid"

export default function HeroSection() {

  return (
    <div className="hero-section home-section">
      <SiteGrid className="hero-content">
        <div className="text-container">
          <h1>The ultimate themes <span className="accent">collection</span></h1>
          <h5>
            Discover the perfect look for your website with our diverse 
            and constantly updated selection of premium themes
          </h5>
          <div className="btn-group">
            <AppButton
              label="View Themes"
              rightIcon="far fa-arrow-right"
              className="main-btn"
              buttonType="primaryGradientBtn"
              url="/themes"
            />
            <AppButton
              label="Try Customizer"
              buttonType="grayBtn"
              url="/customizer"
            />
          </div>
        </div>
      </SiteGrid>
    </div>
  )
}
