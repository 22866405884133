import React, { createContext, useEffect, useState } from 'react'

// @ts-ignore
export const StoreContext = createContext()

const StoreContextProvider = ({children}) => {
 
  const [pageLoading, setPageLoading] = useState(false) 
  const [showMobileSidebar, setShowMobileSidebar] = useState(false)
  const [navScrolled, setNavScrolled] = useState(false)

  useEffect(() => {
    const onNavScrollDown = () => {
      if (window.scrollY > 180) {
        setNavScrolled(true)
      } 
      else {
        setNavScrolled(false)
      }
    }
    window.addEventListener('scroll', onNavScrollDown)
    return () => window.removeEventListener('scroll', onNavScrollDown)
  },[])

  return <StoreContext.Provider value={{ 
    pageLoading, setPageLoading,
    showMobileSidebar, setShowMobileSidebar,
    navScrolled, setNavScrolled
  }}>
    {children}
  </StoreContext.Provider>
}
export default StoreContextProvider