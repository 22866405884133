import React from 'react'
import SiteGrid from "../ui/SiteGrid"
import AppButton from "../ui/AppButton"

export default function Customizer() {
  return (
    <div
      className="customizer-section home-section"
    >
      <SiteGrid>
        <div className="customizer-flex">
          <div className="left">
            <h3>Try the theme<br />customizer</h3>
            <p>Customize your theme with a few clicks and download it immediately.</p>
          </div>
          <AppButton
            label="Get Started"
            buttonType="whiteBtn"
            rightIcon="far fa-arrow-right"
            url="/customizer"
          />
        </div>
      </SiteGrid>
    </div>
  )
}
