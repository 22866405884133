import React, { useState } from 'react'
import SiteGrid from "../ui/SiteGrid"
import { AppInput } from "../ui/AppInputs"
import AppButton from "../ui/AppButton"
import { validateEmail } from "app/utils/generalUtils"
import SectionTitles from "../ui/SectionTitles"

export default function Subscribe() {

  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const handleSubscribe = () => {
    if (!validateEmail(email)) {
      return setError("Please enter a valid email address")
    }
    return setSuccess("Thank you for subscribing! Please check your email for a confirmation link.")
  }

  return (
    <div
      className="subscribe-section home-section"
    >
      <div className="subscribe-bg"/>
      <SiteGrid className="subscribe-flex">
        <SectionTitles
          title="Subscribe"
          text={<>Stay in the <span className="accent">loop</span></>}
          subtitle="Get the latest news, updates and exclusive offers on our latest themes."
          center
        />
        <div className="input-container">
          <div className="top">
            <AppInput
              placeholder="Your email address here..."
              value={email}
              onChange={e => {
                setEmail(e.target.value)
                setError("")
                setSuccess("")
              }}
              onKeyPress={e => e.key === "Enter" && handleSubscribe()}
            />
            <AppButton
              iconBtn
              rightIcon="far fa-arrow-right"
              onClick={handleSubscribe}
            />
          </div>
          <div className="bottom">
            {error && <small className="error">{error}</small>}
            {success && <small className="success">{success}</small>}
          </div>
        </div>
      </SiteGrid>
    </div>
  )
}
