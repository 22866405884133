import React from "react"

export const chatbotSteps = [
  {
    id: '0',
    message: 'Welcome to 789Themes!',
    trigger: '1',
  },
  {
    id: '1',
    message: 'How can i be of assistance today?',
    trigger: '2',
  },
  {
    id: '2',
    options: [
      { value: 1, label: 'How can i customize a theme?', trigger: '4' },
      { value: 2, label: 'Can i get custom theme designed?', trigger: '7' },
      { value: 3, label: 'Other Question', trigger: '8' },
    ],
  },
  {
    id: '4',
    message: 'You can customize any theme by using the Theme Customizer in your account. If you don\'t have an account already you can create one here',
    trigger: '5'
  },
  {
    id: '5',
    component: (
      <div className="chatbot-card">
        <a href="https://789themes.com/theme-customizer">Theme Customizer</a>
      </div>
    ),
    end: true,
  },
  {
    id: '6',
    message: 'You can get a custom theme designed by visiting this link',
    trigger: '6',
    end: true,
  },
  {
    id: '7',
    component: (
      <div className="chatbot-card">
        <a href="https://789themes.com/custom-designs">Get Custom Design</a>
      </div>
    ),
    end: true,
  },
  {
    id: '8',
    message: 'Contact Us',
    user: true,
  },
  {
    id: '10',
    component: (
      <div className="chatbot-card">
        <a href="https://789themes.com/contact">Contact</a>
      </div>
    ),
    end: true,
  },
]