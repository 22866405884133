import React, { useState } from 'react'
import SiteGrid from "../ui/SiteGrid"
import SectionTitles from "../ui/SectionTitles"
import aboutImg from "app/assets/images/about-img-1.jpg"
import AppButton from "../ui/AppButton"
import AppModal from "../ui/AppModal"

export default function AboutUs() {

  const [showVideoModal, setShowVideoModal] = useState(false)

  return (
    <div className="about-section home-section">
      <SiteGrid className="about-flex">
        <div className="text-container">
          <SectionTitles
            title="About"
            text={<>Innovative designs,<br />driven by <span className="accent">you</span></>}
          />
          <p>
            At our core, we are a team of passionate designers committed to crafting
            the perfect website themes to elevate your brand. We pride ourselves on
            creating modern, visually stunning designs that captivate and inspire your
            audience.
            <br /><br />
            We believe that every website deserves to stand out. That's why our team of
            expert designers work tirelessly to create custom, unique themes that capture
            your brand's essence and elevate your online presence.
          </p>
          <div className="btn-group">
            <AppButton
              label="Learn More"
              url="/about"
              className="color-shadow"
            />
            <div
              className="play-video-btn"
              onClick={() => setShowVideoModal(true)}
            >
              <i className="fas fa-play-circle" />
              <h6>Watch Video</h6>
            </div>
          </div>
        </div>
        <div className="img-container">
          <div className="img-content">
            <img src={aboutImg} alt="about" />
          </div>
        </div>
      </SiteGrid>
      <AppModal 
        showModal={showVideoModal}
        setShowModal={setShowVideoModal}
        className="home-video-modal"
      >
          <iframe
            title="video"
            src="https://www.youtube.com/embed/7e90gBu4pas"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
      </AppModal>
    </div>
  )
}
