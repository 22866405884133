import React from 'react'
import SiteGrid from "../ui/SiteGrid"
import { testimonialsArray } from "app/data/general"
import SectionTitles from "../ui/SectionTitles"

export default function Testimonials() {

  const testimonialsRender = testimonialsArray.map((item, index) => {
    return <div
      className="testimonial-item"
      key={index}
    >
      <i className="fas fa-quote-left" />
      <p>{item.text}</p>
      <div className="item-info">
        <img src={item.img} alt={item.name} />
        <div className="texts">
          <h6>{item.name}</h6>
          <small>{item.position}</small>
        </div>
      </div>
    </div>
  })

  return (
    <div
      className="testimonials-section home-section"
    >
      <SiteGrid>
        <SectionTitles
          title="Testimonials"
          text={<>What our clients <span className="accent">say</span></>}
          subtitle="See how our products and services have made a difference in our customers' lives"
          center
        />
        <div className="testimonials-masonry">
          {testimonialsRender}
        </div>
      </SiteGrid>
    </div>
  )
}
