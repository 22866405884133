import React, { useContext } from 'react'
import './styles/AppContainer.css'
import { StoreContext } from "app/store/store"
import PageLoader from "app/components/ui/PageLoader"
import RoutesContainer from "./RoutesContainer"
import Navbar from "app/components/layout/Navbar"
import HelmetTitle from "app/meta/HelmetTitle"
import Footer from "app/components/layout/Footer"
import Sidebar from "app/components/layout/Sidebar"

export default function AppContainer() {

  const { pageLoading } = useContext(StoreContext)

  return (
    <div className="app-container">
      <HelmetTitle />
      <Navbar />
      <Sidebar />
      <div className="main-content">
        <RoutesContainer />
      </div>
      <Footer />
      <PageLoader loading={pageLoading} />
    </div>
  )
}
