import React from 'react'
import './styles/IconContainer.css'

export default function IconContainer(props) {

  const { bgColor, icon, iconColor, iconSize="20px",
    dimensions="30px", round=true, noHover, inverted, 
    onClick, title, badgeValue, badgeBgColor,
    badgeTextColor, style, className='', borderColor } = props

  return (
    <div
      className={`icon-container ${className} ${round ? "round" : ""} ${noHover ? "no-hover" : ""} ${inverted ? "inverted" : ""}`}
      onClick={(e) => onClick && onClick(e)}
      title={title}
      style={{ background: bgColor, width: dimensions, height: dimensions, borderColor, ...style }}
    >
      <i
        className={icon}
        style={{ color: iconColor, fontSize: iconSize }}
      />
      {
        badgeValue > 0 &&
        <div
          className="badge-container"
          style={{ background: badgeBgColor }}
        >
          <small style={{ color: badgeTextColor }}>{badgeValue}</small>
        </div>
      }
    </div>
  )
}
